import * as React from "react";
import Layout from "../components/Layout/Layout";
import HeaderAndParagraph from "../components/HeaderAndParagraph/HeaderAndParagraph";
//@ts-ignore
import * as styles from "./privacy-policy.module.css";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className={`${styles.padding_space}`}>
        <div>
          <h1>PREMESSA</h1>

          <p>
            Al giorno d’oggi, per consolidare il proprio posto nel mercato
            globale, sempre più competitivo ed esigente, una società deve
            contraddistinguersi non solo per i prodotti ed i servizi offerti, ma
            soprattutto per i contenuti ed i valori che rappresenta.  Nel
            proprio operato, la società P&B si preoccupa di agire sempre e
            comunque con integrità etica in tutti i suoi rapporti, estendendo
            tale impegno ai propri dipendenti e collaboratori e ad ogni altro
            interlocutore. L’integrità etica, infatti, è caratteristica
            fondamentale e distintiva della struttura e dell’immagine aziendale.
            In particolare, P&B si impegna ad osservare i principi, le norme e
            quanto altro espressamente previsto dal presente Codice di Condotta
            Aziendale.  P&B opera nel più completo rispetto della legalità, dei
            principi della concorrenza leale, onestà, integrità, correttezza e
            buona fede, salvaguardando gli interessi di clienti, dipendenti,
            collaboratori, fornitori, partner commerciali e finanziari e, più in
            generale, della collettività in cui P&;B opera. Da ciò discende che
            chiunque abbia rapporti lavorativi con P&B è tenuto a rispettare e a
            far rispettare detti principi, in linea con le proprie funzioni e
            responsabilità. Pertanto, un comportamento che si concreti nella
            violazione di detti principi non è in nessun caso giustificabile con
            la convinzione di agire a vantaggio di P&B.  Per tali motivi è stato
            redatto il Codice di Condotta Aziendale, il cui rispetto è alla base
            del buon funzionamento, dell’affidabilità, della reputazione e del
            successo di P&B.
          </p>
        </div>
        <div>
          <h1>PERIMETRO OPERATIVO DEL CODICE</h1>

          <p>
            Le norme di questo codice sono rivolte agli amministratori, ai
            dipendenti, ai collaboratori della Società, nonché chiunque altro,
            direttamente o indirettamente, anche se temporaneamente, abbiano
            rapporti o relazioni con essa. Di conseguenza, agenti, consulenti o
            rappresentati di P&B sono obbligati ad agire in conformità ai canoni
            etici. P&B, dunque, nei limiti di quanto consentito dalla legge, si
            riserva espressamente la facoltà di interrompere ogni rapporto con
            terzi che non si conformino alle prescrizioni del presente Codice di
            Condotta Aziendale.
          </p>
        </div>
        <div>
          <h1>PRINCIPI</h1>
          <h2>Eticità e rispetto della legge</h2>
          <p>
            P&B ha una propria identità culturale fondata su principi di
            legalità, onestà e correttezza. Dovere imprescindibile della società
            è ottenere che tutti i propri lavoratori si riconoscano in questi
            valori e li mettano in pratica costantemente. P&B opera all’interno
            dei limiti previsti dalle norme di legge, dall’etica professionale,
            dal Codice di Condotta Aziendale e dai regolamenti interni. Inoltre
            la società garantisce tempestività negli adeguamenti ad ogni
            modifica legislativa e ad ogni prassi inerente la propria attività,
            assicurandone l’osservanza nonché la reale ed effettiva conoscenza
            ad ogni livello della compagine societaria. P&B protegge la propria
            reputazione mediante il rispetto della legge e la continuazione
            della propria funzione sociale, conscia dell’importante contributo
            fornito allo sviluppo economico del Paese. Da tutto ciò discendono
            il dovere e la necessità di agire eticamente. La tutela degli
            interessi della Società non può mai concretizzarsi in azioni
            contrarie ai principi di legalità, correttezza e onestà. P&B si
            impegna nel raggiungimento dei propri obiettivi senza tralasciare
            l’integrità morale della propria condotta. I rapporti di P&B con i
            terzi, infatti, sono comunque ispirati ai principi di correttezza,
            collaborazione, lealtà e rispetto reciproco. P&B aborre ogni pratica
            commerciale che non si basi sulla valutazione di criteri di qualità
            e serietà dei prodotti e dei servizi, nonché degli interlocutori
            stessi. Per i medesimi motivi, P&B contrasta anche ogni pratica
            commerciale che si realizzi mediante regalie o scambi di denaro o
            favori contrastanti con le indicazioni del presente Codice di
            Condotta Aziendale. In occasione del mancato rispetto di previsioni
            di legge, P&B si riserva la facoltà di rescindere il rapporto
            professionale e di agevolare ogni opportuno accertamento
            processuale, anche (eventualmente) penali. P&B persegue
            costantemente l’eccellenza operativa. Ciò è vero anche per quanto
            concerne sia i rapporti con i dipendenti, con i clienti, con i
            fornitori, con i concorrenti, sia la qualità dei prodotti, la loro
            compatibilità ambientale, la loro utilità sociale.
          </p>
          <h2>Informazione esaustiva e veritiera</h2>
          <p>
            P&B si impegna ad informare in modo completo e trasparente i propri
            interlocutori in merito alla propria situazione, senza praticare
            favoritismi di alcun genere, garantendo inoltre la correttezza delle
            informazioni fornite, anche riguardo ad ogni episodio rilevante ed
            inerente alla gestione societaria e contabile della Società.
          </p>
          <h2>Interessi tutelati</h2>
          <p>
            P&amp;B tutela in via principale, con ogni mezzo legalmente
            consentito, gli interessi dei soci. P&amp;B garantisce il
            trattamento dei dati mediante procedure idonee a proteggere la
            riservatezza delle informazioni in proprio possesso ed il rispetto
            della normativa in materia dei dati personali. Inoltre, data
            l’importanza ed il valore della Privacy, P&amp;B protegge la propria
            riservatezza e dei suoi impiegati, dirigenti, collaboratori e
            partner, mediante l’adozione di ogni precauzione affinché i dati
            personali non vengano divulgati senza le opportune autorizzazioni.
            P&amp;B, infine, si impegna ad evitare la ricerca di dati riservati
            attraverso mezzi illeciti, limitandosi ad ottenere le informazioni
            necessarie per la scelta dei propri partner e per la conoscenza dei
            propri concorrenti nei modi consentiti dalla legge. P&amp;B adotta
            degli standard, volti ad effettuare l’acquisto di beni e servizi,
            basati sul principio economico ma che mai possano pregiudicare la
            sicurezza di persone, lavoratori e terze parti.
          </p>
          <h2>Tutela della persona</h2>
          <p>
            P&B garantisce il rispetto dell’integrità fisica e culturale della
            persona e le relazioni interpersonali, promuovendo ogni forma di
            integrazione umana mediante la valorizzazione dell’interculturalità.
            P&B garantisce che le condizioni di lavoro al proprio interno
            rispettino la dignità individuale e la sicurezza dell’ambiente di
            lavoro, assicurando ogni più aggiornata forma di tutela della
            persona, con riduzione delle possibili fonti di danno, operando
            costantemente con periodica e puntuale formazione delle risorse. 
            P&B non permette richieste o minacce finalizzate a convincere le
            persone ad agire contro la legge o il Codice, o comunque in maniera
            lesiva delle convinzioni e preferenze morali e personali di
            ciascuno.  P&B si impegna affinché i propri contratti di lavoro
            rispettino la legislazione vigente, evitando quotidianamente 
            qualsiasi discriminazione fondata sull’età, sul sesso, sulle
            preferenze gli orientamenti sessuali, sullo stato di salute o di
            famiglia, sulla razza, sul colore, sulla nazionalità, sulle opinioni
            politiche e sulle credenze religiose di ciascuno. P&B impedisce,
            inoltre, che si creino ambienti lavorativi in cui siano consentite o
            addirittura favorite l’intimidazione, l’ostilità reciproca, le
            offese o qualsiasi altra forma di vessazione sulle persone.  P&B
            incentiva e provvede alla formazione ed all’aggiornamento dei propri
            dipendenti, anche in quanto mezzo per conseguire l’eccellenza
            operativa.
          </p>
          <h2>Concorrenza leale</h2>
          <p>
            P&B rispetta e riconosce il valore della concorrenza leale, evitando
            comportamenti finalizzati alla conclusione di affari a proprio
            vantaggio contrari alle norme vigenti. Ritiene, infatti, che la
            concorrenza corretta e leale sia un elemento fondamentale per il
            proprio sviluppo ed aborre ogni forma di regalia, di pagamento
            indebito o di beneficio improprio percepito o promesso. Eventuali
            regali o altre forme di favore sono accettabili solo nei limiti di
            quanto previsto dalla legge, dalle norme del presente Codice di
            Condotta Aziendale e dalle prassi aziendali.
          </p>
          <h2>Rispetto e tutela dell’ambiente</h2>
          <p>
            P&B svolge le proprie attività amministrative e di supporto al
            business tenendo in considerazione l’esigenza di protezione
            dell’ambiente e di uso sostenibile delle risorse naturali,
            conformemente a quanto previsto dalla normativa vigente in materia
            ambientale. In particolare, in materia di rispetto e di tutela
            dell’ambiente, P&B:
          </p>
          <ul>
            <li>
              gestisce i rifiuti prodotti nel rispetto delle normative vigenti,
              anche in materia di autorizzazioni, iscrizioni o comunicazioni
              richieste dalla Pubblica Amministrazione,
            </li>
            <li>
              adotta tutte le misure necessarie a rafforzare la protezione della
              salute umana e dell’ambiente dagli effetti nocivi delle sostanze
              chimiche;
            </li>
            <li>
              opera le proprie scelte di sviluppo e di investimento tenendo in
              considerazione e limitando i potenziali impatti sul territorio e
              sull’ambiente, con particolare attenzione alla tutela dei siti
              protetti.
            </li>
          </ul>
          <h2>La sicurezza dei sistemi informatici</h2>
          <p>
            La Società, al fine di tutelare la riservatezza e la sicurezza dei
            dati in suo possesso, dota i propri sistemi informatici di appositi
            accorgimenti atti a impedire la libera circolazione delle
            informazioni o un impiego non appropriato delle stesse. A tal fine,
            a ciascun dipendente e collaboratore vengono attribuiti un
            identificativo e una password personalizzati e viene consentito
            l’accesso a determinati programmi e files unicamente in
            considerazione delle mansioni svolte. I dipendenti e i collaboratori
            sono tenuti alla segretezza del loro identificativo e della loro
            password: devono pertanto evitare di divulgare tali dati e devono
            modificare regolarmente la loro password. I dipendenti e i
            collaboratori inoltre non devono lasciare la propria postazione di
            lavoro senza aver preso tutte le cautele necessarie a impedire che
            informazioni riservate possano essere visualizzate da soggetti non
            autorizzati.
          </p>
        </div>
        <div>
          <h1>REGOLE COMPORTAMENTALI</h1>
          <h2>Dipendenti e collaboratori</h2>
          <p>
            P&B persegue l’obiettivo fondamentale di tutelare gli interessi ed
            il ruolo delle Risorse Umane, ossia dei propri dipendenti e dei
            collaboratori, cosciente dell’importanza di garantire con essi
            rapporti ispirati alla lealtà ed alla fiducia reciproca. Nella
            stessa maniera, P&B pretende che costoro siano ugualmente rispettosi
            dell’Azienda e dei suoi interessi. P&B, inoltre, si adopera affinché
            tutti i dipendenti/collaboratori si comportino lealmente e
            rispettino gli obblighi previsti dal contratto di lavoro e dal
            presente Codice di Condotta Aziendale. La riservatezza dei dati
            personali dei dipendenti e dei collaboratori è tutelata, secondo le
            previsioni dell’apposita normativa in materia, anche attraverso
            modalità operative che specificano le informazioni ricevute e le
            modalità di trattamento e di conservazione. Non si effettua alcuna
            indagine sulle idee, i gusti personali e la vita privata delle
            persone. Si riportano di seguito alcuni dei comportamenti che P&B
            considera contrari al presente Codice, anche qualora non configurino
            reato:
          </p>
          <ul>
            <li>qualsiasi atto diretto a denigrare colleghi o terzi;</li>
            <li>
              l’impiego, non autorizzato dagli appositi organi di controllo, di
              risorse aziendali a fini personali;
            </li>
            <li>
              qualsiasi tipo di atto – anche se di per sé non illecito –
              potenzialmente lesivo della reputazione di P&B;
            </li>
            <li>
              il perseguimento di interessi privati – nell’attività lavorativa –
              a discapito di P&B.
            </li>
          </ul>
          <p>
            La selezione del personale da assumere è operata alla luce della
            corrispondenza dei profili dei candidati rispetto a quelli richiesti
            ed alle necessità specifiche di P&B, in osservanza dei principi di
            imparzialità e di pari opportunità. Tutto il personale deve essere
            assunto con regolare contratto di lavoro secondo quanto disposto
            dalle norme di legge. P&B evita e contrasta ogni tipo di
            discriminazione nei confronti delle persone. Tutte le scelte
            operante nella gestione e nello sviluppo delle risorse umane, anche
            riguardanti l’accesso a ruoli o incarichi diversi, sono fondate sul
            merito documentato e sulla corrispondenza tra profili richiesti e
            posseduti.  Nell’ambito dei rapporti gerarchici, configura abuso
            della posizione di autorità pretendere, come atto dovuto al
            superiore gerarchico, prestazioni, favori personali e qualunque
            comportamento che violi quanto previsto nel presente Codice di
            Condotta Aziendale, con speciale riguardo alla richiesta di celare
            l’esistenza o alterare fatti in occasione di richieste dell’Autorità
            giudiziaria.   I dipendenti possono usufruire delle strutture e
            delle risorse, rese disponibili da parte di P&B, solo per gli
            obiettivi legali del business aziendale. In questo modo P&B può
            controllare, nei limiti di quanto stabilito dalle norme e dai
            regolamenti in materia di rapporti di lavoro e di privacy dei
            dipendenti, l’impiego che i singoli compiono dei beni sociali, ivi
            incluse le strumentazioni informatiche.  Ogni
            dipendente/collaboratore di P&B è tenuto ad evitare tutte le
            situazioni e tutte le attività in cui si possa manifestare un
            conflitto con gli interessi di P&B o che possano interferire con la
            propria capacità di assumere, in modo imparziale, decisioni nel
            migliore interesse di P&B e nel pieno rispetto della legge e delle
            norme del Codice di Condotta Aziendale.  Ogni
            dipendente/collaboratore di P&B si obbliga ad evitare tutte le
            situazioni e tutte le attività in potenziale conflitto con gli
            interessi di P&B o potenzialmente interferenti con la capacità di
            assumere, imparzialmente, decisioni nel migliore interesse della
            società e nel rispetto della legge e del Codice di Condotta
            Aziendale. Ogni dipendente/collaboratore di P&B deve, inoltre,
            evitare di trarre beneficio personale da atti di disposizione dei
            beni sociali o da opportunità d’affari delle quali sia a conoscenza
            a causa delle proprie funzioni, nei limiti in cui ciò si traduce in
            un potenziale danno per P&B.  In particolare, è vietato:
          </p>
          <ul>
            <li>
              influenzare in qualsiasi modo – in contrasto con la legge o
              all’interesse aziendale – i rapporti di P&B con clienti, fornitori
              o partner con i quali il dipendente/collaboratore abbia personali
              relazioni di amicizia o comunque di conoscenza;
            </li>
            <li>
              rappresentare o favorire per ragioni personali un cliente, un
              fornitore o un partner;
            </li>
            <li>
              interferire in rapporti commerciali non autorizzati con
              concorrenti di P&B;
            </li>

            <li>
              favorire propri interessi personali nella gestione di affari per
              conto di P&B;
            </li>
            <li>
              impiegare il nome o comunque una qualsiasi risorsa di P&B per
              attività esterne non autorizzate esplicitamente;
            </li>
            <li>
              influenzare o favorire, illecitamente o comunque in maniera
              eticamente scorretta, le attività di P&B attraverso l’utilizzo di
              proprie posizioni personali di tipo sociale, amministrativo o
              politico.
            </li>
          </ul>
          <p>
            Ogni dipendente o collaboratore deve immediatamente riferire ai
            propri referenti aziendali in merito a qualunque caso potenzialmente
            idoneo a configurare un conflitto di interesse.  P&B si preoccupa di
            assicurare un ambiente lavorativo rispettoso delle previsioni
            legislative in materia di salute e sicurezza, preoccupandosi
            dell’adozione di opportune prassi aziendali, nonché di comportamenti
            responsabili. P&B, inoltre, si impegna a gestire e prevenire i
            rischi per la salute e la sicurezza di dipendenti e collaboratori,
            legati all’attività professionale, attraverso un’opportuna attività
            di monitoraggio.  Le previsioni della normativa di riferimento in
            tema di salute, sicurezza e ambiente, nonché tutte le misure
            previste dalle procedure e dai regolamenti interni sono obbligatorie
            per dipendenti o collaboratori. Questi ultimi, inoltre,
            compatibilmente alle proprie mansioni, prendono parte all’attività
            di prevenzione dei rischi, di salvaguardia dell’ambiente e di tutela
            della salute e della sicurezza delle persone.
          </p>
          <h2>Clienti e partner commerciali</h2>
          <p>
            P&B si preoccupa di offrire servizi di alta qualità a condizioni
            competitive, sempre nel rispetto della leale concorrenza, tenendo
            sempre presente che i clienti sono la propria risorsa primaria. Nei
            confronti di questi ultimi, infatti, P&B intrattiene rapporti di
            alta professionalità ma comunque ispirati alla disponibilità ed al
            rispetto.   Pertanto P&B richiede ai propri dipendenti e
            collaboratori di:
          </p>
          <ul>
            <li>
              prestare servizi di qualità, che rispecchino le ragionevoli
              aspettative ed appaghino le necessità del cliente, con efficienza,
              cortesia e tempestività, nell’ambito dei termini contrattuali;
            </li>
            <li>
              quando necessario e nei modi e  nelle  forme  previste  dalle 
              politiche  aziendali,  informare accuratamente ed esaurientemente
              il cliente in merito ai prodotti ed ai servizi offerti, in modo
              che egli possa fare le proprie scelte consapevolmente;
            </li>
            <li>
              riportare solo quanto sia veritiero nelle comunicazioni
              pubblicitarie o di altro tipo.
            </li>
          </ul>
          <p>
            P&B pretende che i propri collaboratori esterni osservino principi
            comportamentali analoghi a quelli ai quali anch’essa si adegua. P&B
            sceglie i propri partner, clienti e fornitori in maniera
            trasparente, basandosi su criteri di qualità e competitività
            oggettiva dei servizi e dei prodotti forniti. A riguardo, P&B
            valuta, nella scelta dei propri partner commerciali:
          </p>
          <ul>
            <li>la professionalità e l’esperienza;</li>
            <li>
              l’effettivo possesso, opportunamente  documentato,  di  mezzi, 
              anche  finanziari,  strutture
            </li>
            <li>
              organizzate, capacità e risorse progettuali, know-how, etc.;
            </li>
            <li>
              l’adozione di sistemi volti ad assicurare la qualità, la sicurezza
              e la tutela dell’ambiente.
            </li>
          </ul>
          <h2>
            Rapporti con terzi, in particolare con la Pubblica Amministrazione
          </h2>
          <p>
            I rapporti tra P&B e le Istituzioni Pubbliche sono fondati sui
            principi di correttezza, trasparenza e collaborazione. Si rigetta
            qualsiasi forma di comportamento in cui si ravvisi una natura
            collusiva o idonea a pregiudicare i principi contenuti nel presente
            Codice di condotta aziendale.  La conclusione di accordi di
            qualsiasi tipo con le Pubbliche Amministrazioni e le Istituzioni
            Pubbliche è delegata a soggetti appositamente preposti ed
            autorizzati da P&B. Nessun dipendente, quindi, privo delle adeguate
            qualifiche, può frapporsi fra P&B e una Pubblica Amministrazione
            seppur soltanto per attivare trattative commerciali.  P&amp;B
            rifiuta ogni comportamento suscettibile di essere considerato
            promessa o offerta di pagamento, beni o altre utilità di vario
            genere al fine di promuovere e favorire i propri interessi ed
            ottenerne vantaggio; e, comunque, ogni altra condotta che, benché di
            per sé legale, sia eticamente scorretto.  Qualsiasi soggetto, legato
            ad P&amp;B da rapporti lavorativi,  a cui siano offerti,
            direttamente o indirettamente, utilità o benefici da parte di
            funzionari pubblici, incaricati di pubblico servizio o dipendenti in
            genere della Pubblica Amministrazione o di altre Pubbliche
            Istituzioni, deve immediatamente darne notizia al proprio referente.
          </p>
          <h2>Controllo</h2>
          <p>
            P&amp;B ritiene che un efficace sistema di controllo, tale da non
            inficiare la serenità dell’ambiente lavorativo, sia realizzabile
            solo attraverso la collaborazione dei dipendenti. Pertanto, P&amp;B
            si occupa di promuovere a tutti i livelli la consapevolezza
            dell’esistenza e dell’utilità dei controlli e l’assunzione di una
            mentalità favorevole all’esercizio del controllo, in quanto
            quest’ultimo apporta un contributo positivo al miglioramento
            dell’efficienza aziendale.  Tutti gli accorgimenti necessari o utili
            ad indirizzare, gestire e verificare le attività dell’impresa con la
            finalità di garantire il rispetto delle leggi e delle procedure
            aziendali, la protezione dei beni aziendali, la gestione efficiente
            delle attività, nonché l’accuratezza e la completezza dei dati
            contabili e finanziari sono considerati “controlli interni”.  Ogni
            livello della struttura organizzativa condivide la responsabilità di
            consentire al sistema di controllo interno un funzionamento
            efficace; pertanto, tutti i dipendenti, per quanto di propria
            competenza, sono responsabili dell’osservanza delle prescrizioni da
            cui dipende l’efficienza del sistema di controllo, ed è loro dovere
            notificare immediatamente, attraverso gli appositi strumenti e le
            procedure predisposte da P&amp;B, qualsiasi evento anomalo.  Infine,
            ognuno è responsabile, in quanto custode, dei beni aziendali
            (materiali ed immateriali) strumentali alla propria attività, con
            l’obbligo di utilizzo degli stessi in maniera adeguata ed impedire
            che altri ne facciano un uso improprio.
          </p>
        </div>
        <div>
          <h1>APPLICAZIONE</h1>
        </div>
        <div>
          <p>
            Ogni dipendente/collaboratore di P&B ha l’obbligo di essere al
            corrente delle norme (aggiornate), con riferimento sia a quelle
            dettate dal presente Codice di condotta aziendale che a quelle di
            riferimento, che regolano l’attività svolta, derivanti dalla Legge o
            da procedure e regolamenti interni. Ogni dipendente/collaboratore
            deve inoltre accettare espressamente i propri doveri sanciti nel
            presente Codice di condotta aziendale, al momento della costituzione
            del rapporto di lavoro, o della prima diffusione del Codice di
            condotta aziendale o di sue eventuali modifiche o integrazioni
            rilevanti. In particolare, i dipendenti ed i collaboratori hanno il
            dovere di:
          </p>
          <ul>
            <li>
              non commettere atti contrari alle prescrizioni del Codice di
              condotta aziendale;
            </li>
            <li>
              in caso di necessità, richiedere chiarimenti sulle modalità di
              applicazione di dette prescrizioni direttamente ai propri
              superiori, referenti aziendali o all’organismo preposto alla
              vigilanza sul Codice di condotta aziendale;
            </li>
            <li>
              comunicare immediatamente ai  superiori, ai referenti  aziendali 
              o  all’organismo preposto alla vigilanza  sul  Codice  etico 
              qualsiasi  informazione,  appresa direttamente  o 
              indirettamente,  inerente  a possibili violazioni delle norme del
              Codice di condotta aziendale, nonché qualsiasi richiesta ricevuta
              di violazione delle stesse. Sono vietate le segnalazioni
              effettuate in forma anonima;
            </li>
            <li>
              cooperare con gli incaricati alla verifica delle possibili
              violazioni;
            </li>
            <li>
              informare qualunque  terzo  con  cui  abbiano  contatti 
              nell’ambito  lavorativo dell’esistenza  del Codice di condotta
              aziendale e degli impegni e doveri nascenti dallo stesso anche per
              i soggetti esterni; pertanto, esigere l’osservanza degli obblighi
              attinenti direttamente alla loro attività;
            </li>
            <li>
              attuare idonee  iniziative  interne  e,  qualora  di  propria 
              competenza,  anche  esterne,  in  caso  di violazione dell’obbligo
              di conformarsi alle norme del Codice di condotta aziendale da
              parte di terzi.
            </li>
          </ul>
          <p>
            Se reputato necessario per la salvaguardia degli interessi aziendali
            e compatibilmente con la normativa applicabile, idonei provvedimenti
            disciplinari sono adottati in caso di violazione del Codice di
            condotta aziendale, poiché l’osservanza di quest’ultimo costituisce
            una delle principali obbligazioni contrattuali assunte dai
            dipendenti o collaboratori o dai soggetti che a qualunque titolo
            intrattengano rapporti con P&amp;B. Alla direzione aziendale,
            competono, tra l’altro, i seguenti compiti in ordine all’attuazione
            del Codice di condotta aziendale:
          </p>
          <ul>
            <li>
              controllare l’applicazione del Codice da parte dei soggetti
              interessati;
            </li>
            <li>
              fornire pareri in caso di revisione dei processi interni,
              accertandone la coerenza con il Codice di condotta aziendale.
            </li>
          </ul>
        </div>
        <div>
          <h1>VIGENZA</h1>
        </div>
        <div>
          <p>
            Questo Codice di condotta aziendale ha vigenza immediata ed è
            pubblicizzato in forma digitale. Ha validità fino ad espressa
            modifica da parte di P&B.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
